import { db } from "@/firebase";
import store from "@/store";

let already_listened = {
  admins: false,
  office: false,
  borrower: false,
  investor: false,
  partner: false,
  technician: false,
  project: false,
  testCalendarPayments: false,
  comments: false,
  request: false,
  creditTransfersByPartner: false
};

let listeners = {
  admins: () => {},
  office: () => {},
  borrower: () => {},
  investor: () => {},
  partner: () => {},
  technician: () => {},
  project: () => {},
  comments: () => {},
  request: () => {},
  testCalendarPayments: () => {},
  creditTransfersByPartner: () => {}
};

export default {
  admins: () => {
    if (!already_listened.admins) {
      already_listened.admins = true;
    } else {
      return;
    }

    return new Promise((resolve) => {
      listeners.admins = db
        .collection("users")
        .where("role", "in", ["SUPERADMIN", "ADMIN"])
        .onSnapshot((response) => {
          let admins = [];
          response.forEach((doc) => {
            admins.push({ id: doc.id, ...doc.data() });
          });
          store.commit("admins/SET_ADMINS", admins);
          resolve();
        });
    });
  },

  office: (office_id) => {
    return new Promise((resolve) => {
      listeners.office = db
        .collection("offices")
        .doc(office_id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            store.commit("offices/SET_OFFICE", { id: doc.id, ...doc.data() });
          } else {
            console.log("No such document!");
          }
          resolve();
        });
    });
  },

  borrower: (borrower_id) => {
    return new Promise((resolve) => {
      listeners.borrower = db
        .collection("users")
        .doc(borrower_id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            store.commit("borrowers/SET_BORROWER", { id: doc.id, ...doc.data() });
          } else {
            console.log("No such document!");
          }
          resolve();
        });
    });
  },

  investor: (investor_id) => {
    return new Promise((resolve, reject) => {
      try {
        listeners.investor = db
          .collection("users")
          .doc(investor_id)
          .onSnapshot((doc) => {
            if (doc.exists) {
              store.commit("investors/SET_INVESTOR", { id: doc.id, ...doc.data() });
            } else {
              console.log("No such document!");
            }
            resolve();
          });
      } catch (error) {
        return reject(error);
      }
    });
  },

  partner: (partner_id) => {
    return new Promise((resolve) => {
      listeners.partner = db
        .collection("partners")
        .doc(partner_id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            store.commit("partners/SET_PARTNER", { id: doc.id, ...doc.data() });
          } else {
            console.log("No such document!");
          }
          resolve();
        });
    });
  },

  technician: (technician_id) => {
    return new Promise((resolve) => {
      listeners.technician = db
        .collection("users")
        .doc(technician_id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            store.commit("technicians/SET_TECHNICIAN", { id: doc.id, ...doc.data() });
          } else {
            console.log("No such document!");
          }
          resolve();
        });
    });
  },

  project: (project_id) => {
    return new Promise((resolve) => {
      listeners.project = db
        .collection("projects")
        .doc(project_id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            store.commit("projects/SET_PROJECT", { id: doc.id, ...doc.data() });
          } else {
            console.log("No such document!");
          }
          resolve();
        });
    });
  },

  comments: (project_id) => {
    return new Promise((resolve) => {
      listeners.comments = db
        .collection("comments")
        .where("project_id", "==", project_id)
        .onSnapshot((response) => {
          let comments = [];
          response.forEach((doc) => {
            comments.push({ id: doc.id, ...doc.data() });
          });
          store.commit("projects/SET_COMMENTS", comments);
          resolve();
        });
    });
  },

  request: (request_id) => {
    return new Promise((resolve) => {
      listeners.request = db
        .collection("requests")
        .doc(request_id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            store.commit("requests/SET_REQUEST", { id: doc.id, ...doc.data() });
          } else {
            console.log("No such document!");
          }
          resolve();
        });
    });
  },

  testCalendarPayments: (test_id) => {
    return new Promise((resolve) => {
      listeners.testCalendarPayments = db
        .collection("testing")
        .doc(test_id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            store.commit("projects/SET_TEST_CALENDAR_PAYMENTS", { id: doc.id, ...doc.data() });
          } else {
            console.log("No such document!");
          }
          resolve();
        });
    });
  },

  creditTransfersByPartner: (partner_id) => {
    return new Promise((resolve) => {
      listeners.creditTransfersByPartner = db
        .collection("money_transfers")
        .where("partner_id", "==", partner_id)
        .onSnapshot((response) => {
          let creditTransfers = [];
          response.forEach((doc) => {
            creditTransfers.push({ id: doc.id, ...doc.data() });
          });
          store.commit("creditTransfers/SET_CREDIT_TRANSFERS", creditTransfers);
          resolve();
        });
    });
  },

  stop_listeners: () => {
    listeners.admins();
    listeners.office();
    listeners.borrower();
    listeners.investor();
    listeners.partner();
    listeners.technician();
    listeners.project();
    listeners.comments();
    listeners.request();
    listeners.testCalendarPayments();
    listeners.creditTransfersByPartner();

    already_listened.admins = false;
    already_listened.office = false;
    already_listened.borrower = false;
    already_listened.investor = false;
    already_listened.partner = false;
    already_listened.technician = false;
    already_listened.project = false;
    already_listened.comments = false;
    already_listened.request = false;
    already_listened.testCalendarPayments = false;
    already_listened.creditTransfersByPartner = false;
  },

  stop_listener: (listener_name) => {
    listeners[listener_name]();
    already_listened[listener_name] = false;
  }
};
