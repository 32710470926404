import Vue from "vue";
import Vuetify, {
  VContainer,
  VRow,
  VCol,
  VCard,
  VIcon,
  VProgressLinear,
  VTextField,
  VInput,
  VAvatar,
  VList,
  VListGroup,
  VListItemGroup,
  VListItem,
  VResponsive,
  VImg,
  VToolbar,
  VItemGroup,
  VDivider
} from "vuetify/lib";

import es from "vuetify/es5/locale/es";

Vue.use(Vuetify, {
  components: {
    VContainer,
    VRow,
    VCol,
    VCard,
    VIcon,
    VProgressLinear,
    VTextField,
    VInput,
    VAvatar,
    VList,
    VListGroup,
    VListItemGroup,
    VListItem,
    VResponsive,
    VImg,
    VToolbar,
    VItemGroup,
    VDivider
  }
});

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es"
  },
  theme: {
    themes: {
      light: {
        primary: "#00f9ad",
        secondary: "#f5f5f5",
        anchor: "#249240"
      }
    }
  }
});
