import { MicrowdAPI } from "@/firebase";
import elasticSearchApi from "@/api/elasticSearchApi";

// _____________________________________ STATE _____________________________________
const state = {
  financialProducts: []
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  async getFinancialProducts({ commit }, data) {
    commit("SET_FINANCIAL_PRODUCTS", []);

    try {
      const response = await elasticSearchApi.post("/financial-product", data);
      commit("SET_FINANCIAL_PRODUCTS", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  async createFinancialProduct(_, newFinancialProduct) {
    await MicrowdAPI({ action: "createFinancialProduct", data: newFinancialProduct });
  },

  async editFinancialProduct(_, product) {
    await MicrowdAPI({ action: "editFinancialProduct", data: product });
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_FINANCIAL_PRODUCTS(state, financialProducts) {
    state.financialProducts = financialProducts;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
