import { MicrowdAPI } from "@/firebase";
import elasticSearchApi from "@/api/elasticSearchApi";

// _____________________________________ STATE _____________________________________
const state = {
  technicians: [],
  totalCountTechnicians: 0,
  technician: {}
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  async createTechnician(_, newTechnicianObj) {
    await MicrowdAPI({ action: "createTechnician", data: newTechnicianObj });
  },

  async getTechnicians({ commit }, data) {
    commit("SET_TECHNICIANS", []);

    try {
      const response = await elasticSearchApi.post("/technician", data);

      commit("SET_TOTAL_COUNT_TECHNICIANS", response.data.total);
      commit("SET_TECHNICIANS", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getTechnician(_, data) {
    try {
      const response = await elasticSearchApi.post("/technician", data);
      return response.data.data[0];
    } catch (error) {
      console.log(error);
    }
  },

  async editTechnician(_, technician) {
    await MicrowdAPI({ action: "editTechnician", data: technician });
  },

  async disableTechnician(_, technician_id) {
    await MicrowdAPI({ action: "disableTechnician", data: technician_id });
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_TECHNICIANS(state, technicians) {
    state.technicians = technicians;
  },

  SET_TECHNICIAN(state, technician) {
    state.technician = technician;
  },

  SET_TOTAL_COUNT_TECHNICIANS(state, totalCount) {
    state.totalCountTechnicians = totalCount;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
