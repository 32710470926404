import { MicrowdAPI } from "@/firebase";

// _____________________________________ STATE _____________________________________
const state = {
  admins: []
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  async createAdmin(_, newAdmin) {
    await MicrowdAPI({ action: "createUser", data: newAdmin });
  },

  async editAdmin(_, admin) {
    await MicrowdAPI({ action: "editUser", data: admin });
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_ADMINS(state, data) {
    state.admins = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
