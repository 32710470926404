import { MicrowdAPI } from "@/firebase";
import elasticSearchApi from "@/api/elasticSearchApi";
import documentGeneratorApi from "@/api/documentGeneratorApi";

// _____________________________________ STATE _____________________________________
const state = {
  partners: [],
  totalCountPartners: 0,
  partner: {}
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  async createPartner(_, newPartner) {
    await MicrowdAPI({ action: "createPartner", data: newPartner });
  },

  async getPartners({ commit }, data) {
    commit("SET_PARTNERS", []);

    try {
      const response = await elasticSearchApi.post("/partner", data);

      commit("SET_TOTAL_COUNT_PARTNERS", response.data.total);
      commit("SET_PARTNERS", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getPartner(_, data) {
    try {
      const response = await elasticSearchApi.post("/partner", data);
      return response.data.data[0];
    } catch (error) {
      console.log(error);
    }
  },

  async editPartner(_, partner) {
    await MicrowdAPI({ action: "editPartner", data: partner });
  },

  async generateRepaymentsReport(_, partner_id) {
    const { data } = await documentGeneratorApi.get(`/project/repayments/${partner_id}`);
    return data;
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_PARTNERS(state, partners) {
    state.partners = partners;
  },

  SET_TOTAL_COUNT_PARTNERS(state, totalCount) {
    state.totalCountPartners = totalCount;
  },

  SET_PARTNER(state, partner) {
    state.partner = partner;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
