import { MicrowdAPI } from "@/firebase";
import form_selects_values from "./form_selects_values";
import elasticSearchApi from "@/api/elasticSearchApi";
import documentGeneratorApi from "@/api/documentGeneratorApi";

// _____________________________________ STATE _____________________________________
const state = {
  requests: [],
  totalCountRequests: 0,
  request: {},
  form_selects_values: form_selects_values
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  async createRequest(_, newRequest) {
    await MicrowdAPI({ action: "createRequest", data: newRequest });
  },

  async getRequests({ commit }, data) {
    console.log(data);
    commit("SET_REQUESTS", []);

    try {
      const response = await elasticSearchApi.post("/request", data);

      commit("SET_TOTAL_COUNT_REQUESTS", response.data.total);
      commit("SET_REQUESTS", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  async editRequest(_, request) {
    await MicrowdAPI({ action: "editRequest", data: request });
  },

  async sendRequest(_, requestId) {
    await MicrowdAPI({ action: "sendRequest", data: requestId });
  },

  async approveRequest(_, requestId) {
    await MicrowdAPI({ action: "approveRequest", data: requestId });
  },

  // Return the history of credits of a borrower
  async checkBorrowerCreditHistory(_, borrower_id) {
    const response = await MicrowdAPI({ action: "checkBorrowerCreditHistory", data: borrower_id });
    return response.data.data;
  },

  async rejectRequest(_, { request_id, reason, comment }) {
    await MicrowdAPI({ action: "rejectRequest", data: { request_id, reason, comment } });
  },

  async addRequestImage(_, { request_id, image, file }) {
    await MicrowdAPI({ action: "addRequestImage", data: { request_id, image, file } });
  },

  async deleteRequest(_, requestId) {
    await MicrowdAPI({ action: "deleteRequest", data: requestId });
  },

  // Download all requests report
  async downloadAllRequestsReport() {
    const { data } = await documentGeneratorApi.get("/request");
    return data;
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_REQUESTS(state, requests) {
    state.requests = requests;
  },

  SET_TOTAL_COUNT_REQUESTS(state, totalCount) {
    state.totalCountRequests = totalCount;
  },

  SET_REQUEST(state, request) {
    state.request = request;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
