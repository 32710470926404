import { db, rtdb, MicrowdAPI } from "@/firebase";
import elasticSearchApi from "@/api/elasticSearchApi";

// _____________________________________ STATE _____________________________________
const state = {
  investment_info: null,
  commissions: [],
  min_amounts_to_deposit: {},
  percentage_hide_projects: 0,
  investmentsSettingsVersions: [],
  investments: []
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  // Get investment info
  async getInvestmentInfo({ commit }, investment_id) {
    const response = await db.collection("investments").doc(investment_id).get();
    commit("SET_INVESTMENT_INFO", { id: response.id, ...response.data() });
  },

  // Get list of ivestments of project or project bag
  async getInvestments({ commit }, data) {
    try {
      const response = await elasticSearchApi.post("/investment", data);
      commit("SET_INVESTMENTS", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  // Get list of commissions for investment
  async getInvestmentsSettings({ commit }) {
    const investments_version = await rtdb.ref("settings/investments").orderByChild("status/active").equalTo(true).once("value");
    let { data } = investments_version.val()[Object.keys(investments_version.val())[0]];
    let commissions = data.commissions;
    commissions = Object.keys(commissions).map((key) => ({ key, value: commissions[key] }));

    commit("SET_COMMISSIONS", commissions);
    commit("SET_MIN_AMOUNTS_TO_DEPOSIT", data.min_amounts_to_deposit);
    commit("SET_PERCENTAGE_HIDE_PROJECTS", data.percentage_hide_projects);
  },

  // Get list of versions of investments settings
  async getInvestmentsSettingsVersions({ commit }) {
    const response = await rtdb.ref("settings/investments").once("value");
    if (response.exists()) {
      const investmentsSettings = Object.keys(response.val()).map((key) => ({ key, value: response.val()[key] }));
      commit("SET_INVESTMENTS_SETTINGS_VERSIONS", investmentsSettings);
    }
  },

  // Create new investments settings version
  async createNewInvestmentSettings({ dispatch }, newInvestmentsSettingsVersion) {
    await MicrowdAPI({ action: "createNewInvestmentSettings", data: newInvestmentsSettingsVersion });
    await dispatch("getInvestmentsSettingsVersions");
  },

  // Active version
  async activateInvestmentSettings({ dispatch }, versionKey) {
    await MicrowdAPI({ action: "activateInvestmentSettings", data: versionKey });
    await dispatch("getInvestmentsSettingsVersions");
  },

  // Delete investment
  async deleteInvestment(_, investmentId) {
    await MicrowdAPI({ action: "deleteInvestment", data: investmentId });
  },

  // Change investor commission
  async editInvestorCommission(_, data) {
    await MicrowdAPI({ action: "editInvestorCommission", data: data });
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_INVESTMENTS_SETTINGS_VERSIONS(state, data) {
    state.investmentsSettingsVersions = data;
  },

  SET_INVESTMENTS(state, investments) {
    state.investments = investments;
  },

  SET_INVESTMENT_INFO(state, data) {
    state.investment_info = data;
  },

  SET_COMMISSIONS(state, data) {
    state.commissions = data;
  },

  SET_MIN_AMOUNTS_TO_DEPOSIT(state, data) {
    state.min_amounts_to_deposit = data;
  },

  SET_PERCENTAGE_HIDE_PROJECTS(state, data) {
    state.percentage_hide_projects = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
