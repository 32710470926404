import { db, MicrowdAPI } from "@/firebase";

// _____________________________________ STATE _____________________________________
const state = {
  creditTransfers: []
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  async calculateCreditsTransfers(_, partner_id) {
    const response = await MicrowdAPI({ action: "calculateCreditsTransfers", data: { partner_id } });
    return response.data.data;
  },

  async getTotalAmountSentByOffice(_, officeIds) {
    const response = await MicrowdAPI({ action: "getTotalAmountSentByOffice", data: officeIds });
    return response.data.data;
  },

  async createMoneyTransfers(_, data) {
    await MicrowdAPI({ action: "createMoneyTransfers", data });
  },

  async editMoneyTransfer(_, data) {
    await MicrowdAPI({ action: "editMoneyTransfer", data });
  },

  // Get credit trasnfer info
  async getCreditTransfer(_, id) {
    const response = await db.collection("money_transfers").doc(id).get();
    return { id: response.id, ...response.data() };
  },

  // Change trnasfer status to sent
  async changeMoneyTransferStatusToSent(_, transferId) {
    await MicrowdAPI({ action: "changeMoneyTransferStatusToSent", data: transferId });
  },

  // Delete money transfer
  async deleteMoneyTransfer(_, transferId) {
    await MicrowdAPI({ action: "deleteMoneyTransfer", data: transferId });
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_CREDIT_TRANSFERS(state, creditTransfers) {
    state.creditTransfers = creditTransfers;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
