<template>
  <v-app>
    <loading-bar />
    <LayoutConfig>
      <snackbar />
      <router-view />
    </LayoutConfig>
  </v-app>
</template>

<script>
import LoadingBar from "./components/ui/Loading";
import Snackbar from "./components/ui/SnackBar";

export default {
  name: "App",
  components: { LoadingBar, Snackbar },
  async created() {
    await this.$store.dispatch("currencies/getRates");
  }
};
</script>

<style>
@import "./assets/css/main.css";
</style>
