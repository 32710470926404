import { MicrowdAPI, rtdb } from "@/firebase";

// _____________________________________ STATE _____________________________________
const state = {
  howKnowUsOptions: [],
  tirList: null
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  async addHowKnowUs({ dispatch }, option) {
    await MicrowdAPI({ action: "addHowKnowUs", data: option });
    await dispatch("getHowKnowUsOptions");
  },

  // Get list of howKnowUs options
  async getHowKnowUsOptions({ commit }) {
    const response = await rtdb.ref("settings/howknowus").once("value");
    if (response.exists()) {
      const howKnowUsOptions = Object.keys(response.val()).map((key) => ({ key, value: response.val()[key] }));
      commit("SET_HOW_KNOW_US_OPTIONS", howKnowUsOptions);
    }
  },

  // Edit howKnowUs options
  async editHowKnowUs({ dispatch }, { id, active }) {
    await MicrowdAPI({ action: "editHowKnowUs", data: { id, active } });
    await dispatch("getHowKnowUsOptions");
  },

  // Update general conditions
  async updateGeneralConditions(_, data) {
    await MicrowdAPI({ action: "updateGeneralConditions", data });
  },

  // Get list of tir
  async getTirList({ commit }) {
    const response = await rtdb.ref("tir").once("value");
    if (response.exists()) commit("SET_TIR_LIST", response.val());
  },

  // Recalculate historical TIR
  async calculateHistoricalTIR() {
    await MicrowdAPI({ action: "calculateHistoricalTIR" });
  },

  // Get last general conditions
  async getGeneralConditions() {
    const response = await MicrowdAPI({ action: "getGeneralConditions", data: null, hide_snackbar: true });
    return response;
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_HOW_KNOW_US_OPTIONS(state, data) {
    state.howKnowUsOptions = data;
  },

  SET_TIR_LIST(state, data) {
    state.tirList = data;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
