import Vue from "vue";
import Vuex from "vuex";
import { auth } from "@/firebase";
import VuexPersistence from "vuex-persist";
import { MicrowdAPI } from "@/firebase";

// ------------- Modules -------------
import authentication from "@/modules/authentication";
import user from "@/modules/user";
import admins from "@/modules/admins";
import investors from "@/modules/investors";
import technicians from "@/modules/technicians";
import borrowers from "@/modules/borrowers";
import partners from "@/modules/partners";
import offices from "@/modules/offices";
import projects from "@/modules/projects";
import requests from "@/modules/requests";
import investments from "@/modules/investments";
import locations from "@/modules/locations";
import currencies from "@/modules/currencies";
import sectors from "@/modules/sectors";
import products from "@/modules/products";
import creditTransfers from "@/modules/credit-transfers";
import settings from "@/modules/settings";
import projectBags from "@/modules/project-bags";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["authentication"]
});

const store = new Vuex.Store({
  state: {
    loading: false,
    loadingListener: false,
    editing: false, // Check if any form is editing
    errorCode: "",
    errorList: [],
    snackbar: {
      show: false,
      text: "",
      color: ""
    },
    drawer: true
  },
  actions: {
    async checkAuth({ commit, dispatch }) {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          await dispatch("user/getCurrentUserInfo", { root: true });
        } else {
          commit("user/SET_USER", null, { root: true });
        }
      });
    },

    // Add storage path of profile image to user in database
    async addUserPhoto(_, { user_id, file }) {
      await MicrowdAPI({ action: "addUserPhoto", data: { user_id, file } });
    }
  },
  mutations: {
    SET_LOADING(state, bool) {
      state.loading = bool;
    },

    SET_ERROR_CODE(state, code) {
      state.errorCode = code;
    },

    SET_ERROR_LIST(state, list) {
      state.errorList = list;
    },

    SET_LOADING_LISTENER(state, bool) {
      state.loadingListener = bool;
    },

    SET_EDITING(state, bool) {
      state.editing = bool;
    },

    SET_SNACKBAR(state, params) {
      state.snackbar.show = params.show;
      state.snackbar.text = params.text;
      state.snackbar.color = params.color;
    },

    SET_DRAWER(state, bool) {
      state.drawer = bool;
    }
  },
  modules: {
    authentication,
    user,
    admins,
    investors,
    technicians,
    borrowers,
    partners,
    offices,
    projects,
    requests,
    investments,
    locations,
    currencies,
    sectors,
    products,
    creditTransfers,
    settings,
    projectBags
  },
  plugins: [vuexLocal.plugin]
});

export default store;

// Initial load check if user is authenticated
store.dispatch("checkAuth");
