<template>
  <v-snackbar v-model="snackbar.show" :timeout="timeout" :color="snackbar.color" top text>
    {{ snackbar.text }}

    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="SET_SNACKBAR({ show: false, text: '', color: '' })"> cerrar </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      timeout: 4000
    };
  },
  computed: {
    ...mapState(["snackbar"])
  },
  methods: {
    ...mapMutations(["SET_SNACKBAR"])
  }
};
</script>
