import { MicrowdAPI } from "@/firebase";
import elasticSearchApi from "@/api/elasticSearchApi";
import documentGeneratorApi from "@/api/documentGeneratorApi";

// _____________________________________ STATE _____________________________________
const state = {
  borrowers: [],
  totalCountBorrowers: 0,
  borrower: {}
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  async createBorrower(_, newBorrowerObj) {
    const response = await MicrowdAPI({ action: "createBorrower", data: newBorrowerObj });
    return response.data.data;
  },

  async getBorrower(_, data) {
    try {
      const response = await elasticSearchApi.post("/borrower", data);
      return response.data.data[0];
    } catch (error) {
      console.log(error);
    }
  },

  async getBorrowers({ commit }, data) {
    commit("SET_BORROWERS", []);

    try {
      const response = await elasticSearchApi.post("/borrower", data);
      commit("SET_TOTAL_COUNT_BORROWERS", response.data.total);
      commit("SET_BORROWERS", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  async editBorrower(_, borrower) {
    await MicrowdAPI({ action: "editBorrower", data: borrower });
  },

  // Download all borrowers report
  async downloadAllBorrowersReport() {
    const { data } = await documentGeneratorApi.get("/borrower");
    return data;
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_BORROWERS(state, borrowers) {
    state.borrowers = borrowers;
  },

  SET_TOTAL_COUNT_BORROWERS(state, totalCount) {
    state.totalCountBorrowers = totalCount;
  },

  SET_BORROWER(state, borrower) {
    state.borrower = borrower;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
