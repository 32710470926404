import { auth } from "@/firebase";
import router from "@/router";
import listeners from "@/firebase/listeners";

export async function login({ dispatch }, { email, password }) {
  await auth.signInWithEmailAndPassword(email, password);
  const currentUser = auth.currentUser;
  const { claims } = await currentUser.getIdTokenResult();

  if (claims.role === "SUPERADMIN" || claims.role === "ADMIN" || claims.role === "TECHNICIAN" || claims.role === "SUPERTECHNICIAN") {
    router.push("/");
  } else dispatch("logout");
}

export async function logout({ commit }) {
  listeners.stop_listeners();
  await auth.signOut();
  commit("user/SET_USER", null, { root: true });
  commit("SET_TOKEN", "");
}

// Check current user logged in
export function authCurrentUser({ commit }) {
  return new Promise((resolve, reject) => {
    const unsuscribe = auth.onAuthStateChanged(
      async (user) => {
        if (user) {
          const currentUser = auth.currentUser;
          const { token } = await currentUser.getIdTokenResult();
          commit("SET_TOKEN", token);
        }

        unsuscribe();
        resolve(user);
      },
      () => {
        reject();
      }
    );
  });
}
