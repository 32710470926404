import axios from "axios";
import store from "@/store";

const elasticSearchApi = axios.create({
  baseURL: `${process.env.VUE_APP_ELASTIC_URL}/api`,
  headers: { ELASTIC_SERVICE_SECRET_KEY: "secret" }
});

// Request interceptor
elasticSearchApi.interceptors.request.use(
  function (config) {
    const currentToken = store.state.authentication.token;
    if (currentToken) config.headers.common["Authorization"] = `Bearer ${currentToken}`;
    return config;
  },
  function (error) {
    console.log("elasticSearchApi request error -> ", error);
  }
);

// Response interceptor
elasticSearchApi.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    if (response.data.code === "invalid-auth") store.dispatch("authentication/logout");
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    console.log("elasticSearchApi response error -> ", error);
  }
);

export default elasticSearchApi;
