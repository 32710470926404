export default {
  education_levels: [
    "INCOMPLETE_PRIMARY",
    "COMPLETE_PRIMARY",
    "INCOMPLETE_SECONDARY",
    "COMPLETE_SECONDARY",
    "INCOMPLETE_TERTIARY",
    "COMPLETE_TERTIARY",
    "INCOMPLETE_UNIVERSITY",
    "COMPLETE_UNIVERSITY",
    "INCOMPLETE_GRADUATE",
    "COMPLETE_GRADUATE",
    "NOT_SAY",
    "NONE"
  ],
  civils_status: ["MARRIED", "SINGLE", "DIVORCEE", "WIDOW", "FREE_UNION", "SEPARATED"],
  customer_types: ["FARM", "URBAN"],
  phone_types: ["SMARTPHONE", "OLD_PHONE", "NONE"],
  living_places: ["YOUR_OWN", "RENTED"],
  savings_targets: ["UNFORESEEN", "IMPROVE_LIFE", "HEALTH", "NOT_SAVE", "OTHERS", "PERSONAL_OBJECTIVES", "REINVEST_BUSINESS"],
  business_objetives: ["IMPROVE_EXISTING_BUSINESS", "NEW_BUSINESS"],
  investments_money: ["RAW_MATERIALS", "PRODUCT_SALE", "IMPROVE_EFFICIENCY", "MATERIAL_IMPROVEMENT", "HIRE_EMPLOYEES", "OTHERS"],
  funding_sources: [
    "ANOTHER_WORSE_LOAN",
    "ANOTHER_LOAN_THE_SAME",
    "ANOTHER_BETTER_LOAN",
    "DONATIONS",
    "DOES_NOT_HAVE",
    "INFORMAL_FUNDER",
    "NONE"
  ],
  interested_products: [
    "MEDICAL_ASSISTANCE_INSURANCE",
    "ONCOLOGICAL_INSURANCE",
    "BUSINESS_INSURANCE",
    "MORTGAGE",
    "DEBIT",
    "LIFE_INSURANCE",
    "FAMILY_INSURANCE",
    "SOLAR_ENERGY_CREDITS"
  ],
  product_types: ["UNIT_PRODUCTS", "SERVICES_OR_VARIOUS_PRODUCTS"],
  payments_delays: ["NOT", "THREE_OR_LESS", "MORE_THAN_THREE", "UNPAID_DEBT"],
  have_job: ["HER_AND_FAMILY", "HER", "HUSBAND_AND_CHILDREN", "NONE"],
  accounts_control: ["EVERY_DAY", "ONCE_WEEK", "ONCE_MONTH", "ANOTHER_PERSON", "NO_ONE"],
  opinions_borrower: [
    {
      text: "Es una persona respetada, en la que se puede confiar",
      value: 0
    },
    {
      text: "Se puede confiar en ella, pero no todo el mundo le prestaría",
      value: 1
    },
    {
      text: "No puede confiar al 100% en ella, habría que conocerla bien antes",
      value: 2
    },
    {
      text: "No se puede confiar en ella",
      value: 3
    }
  ],
  recommended_borrower: [
    {
      text: "Sí, una mujer extraordinaria Microwd al corriente de pagos",
      value: 0
    },
    {
      text: "Sí, una persona ajena a Microwd",
      value: 1
    },
    {
      text: "Sí, una mujer Microwd que tiene o ha tenido retrasos",
      value: 2
    },
    {
      text: "No",
      value: 3
    }
  ],
  know_more_answers: [
    {
      text: "Totalmente de acuerdo",
      value: 0
    },
    {
      text: "Algo de acuerdo",
      value: 1
    },
    {
      text: "Ni de acuerdo ni en desacuerdo",
      value: 2
    },
    {
      text: "Algo en desacuerdo",
      value: 3
    },
    {
      text: "Totalmente en desacuerdo",
      value: 4
    }
  ],
  payment_capacity_answers: [
    {
      text: "Más de un 300% de la cuota",
      value: 0
    },
    {
      text: "Entre un 100% y un 300% de la cuota",
      value: 1
    },
    {
      text: "Entre un 50% y un 100% de la cuota",
      value: 2
    },
    {
      text: "Menos de un 50% de la cuota",
      value: 3
    }
  ],
  know_business_answers: [
    {
      text: "Conoce aproximadamente la cantidad que va a generar",
      value: 0
    },
    {
      text: "Intuye vagamente cuántos ingresos tendrá",
      value: 1
    },
    {
      text: "No es capaz de saber cuántos ingresos tendrá",
      value: 2
    }
  ]
};
