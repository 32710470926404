<template>
  <v-overlay v-if="loading || loadingListener" z-index="301" class="text-center">
    <v-progress-circular indeterminate color="#00f9ad" />
    <p v-if="tooLong" class="mt-5">Esto puede tardar varios minutos...</p>
  </v-overlay>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      tooLong: false
    };
  },
  computed: {
    ...mapState(["loading", "loadingListener"])
  },
  created() {
    setTimeout(function () {
      this.tooLong = true;
    }, 5000);
  }
};
</script>
