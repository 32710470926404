import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import LayoutConfig from "./layouts/LayoutConfig";
import vuetify from "./plugins/vuetify";
import TiptapVuetifyPlugin from "./plugins/tiptap-vuetify";
import VueApexCharts from "vue-apexcharts";

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "md" // optional, default to 'md' (default vuetify icons before v2.0.0)
});

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.component("LayoutConfig", LayoutConfig);

const env = process.env.VUE_APP_ENVIRONMENT || "development";
Vue.config.devtools = env === "development" || env === "testing" || env === "staging";
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
