import store from "@/store";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/database";

if (!firebase.apps.length) {
  const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
}

const MicrowdAPI = async (params) => {
  store.commit("SET_LOADING", true);
  const MicrowdAPI = firebase.app().functions("europe-west2").httpsCallable("MicrowdAPI");

  try {
    const response = await MicrowdAPI(params);

    if (response.data.error) {
      console.log("Error code: ", response.data.code);
      console.log("Errors List -> ", response.data.errors);
      store.commit("SET_ERROR_CODE", response.data.code);
      store.commit("SET_ERROR_LIST", response.data.errors);
      throw new Error(response.data.message);
    }
    if (!params.hide_snackbar) store.commit("SET_SNACKBAR", { show: true, text: response.data.message, color: "success" });

    return response;
  } catch (error) {
    console.log(params.action, error.message);
    store.commit("SET_SNACKBAR", { show: true, text: error.message, color: "error" });
  } finally {
    store.commit("SET_LOADING", false);
  }
};

const uploadToStorage = async (params) => {
  store.commit("SET_LOADING_LISTENER", true);
  const storageRef = storage.ref().child(params.child);

  try {
    await storageRef.put(params.file);
  } catch (error) {
    console.log(error);
    store.commit("SET_LOADING_LISTENER", false);
    store.commit("SET_SNACKBAR", { show: true, text: error.message, color: "error" });
  }
};

const auth = firebase.auth();
const db = firebase.firestore();
const rtdb = firebase.database();
const storage = firebase.storage();
export { db, rtdb, auth, uploadToStorage, storage, MicrowdAPI };
