import { MicrowdAPI } from "@/firebase";
import elasticSearchApi from "@/api/elasticSearchApi";
import documentGeneratorApi from "@/api/documentGeneratorApi";

// _____________________________________ STATE _____________________________________
const state = {
  investors: [],
  investor: {},
  totalCountInvestors: 0,
  totalCountWithdraws: 0,
  withdraws: []
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  // -------------------------------------- RETAIL --------------------------------------
  async registerRetailInvestor(_, newInvestorObj) {
    await MicrowdAPI({ action: "registerRetailInvestor", data: newInvestorObj });
  },

  async editRetailInvestor(_, investor) {
    await MicrowdAPI({ action: "editRetailInvestor", data: investor });
  },

  // Add storage path of identity document image/s to user in database
  async addUserIdentityDocument(_, { user_id, file, orientation }) {
    await MicrowdAPI({ action: "addUserIdentityDocument", data: { user_id, file, orientation } });
  },

  // Verify identity documents
  async verifyUserIdentityDocument(_, investorId) {
    await MicrowdAPI({ action: "verifyUserIdentityDocument", data: investorId });
  },

  // Unverify identity documents
  async unverifyUserIdentityDocument(_, investorId) {
    await MicrowdAPI({ action: "unverifyUserIdentityDocument", data: investorId });
  },

  // Verify user email
  async verifyUserEmail(_, user_id) {
    await MicrowdAPI({ action: "verifyUserEmail", data: { user_id } });
  },

  // Accept contract conditions
  async acceptInvestorConditions(_, data) {
    await MicrowdAPI({ action: "acceptInvestorConditions", data: data });
  },

  // -------------------------------------- FUND --------------------------------------
  async createFundInvestor(_, newInvestorObj) {
    await MicrowdAPI({ action: "createFundInvestor", data: newInvestorObj });
  },

  async editFundInvestor(_, investor) {
    await MicrowdAPI({ action: "editFundInvestor", data: investor });
  },

  // -------------------------------------- GENERAL --------------------------------------
  async getInvestors({ commit }, data) {
    commit("SET_INVESTORS", []);

    try {
      const response = await elasticSearchApi.post("/investor", data);

      commit("SET_INVESTORS", response.data.data);
      commit("SET_TOTAL_COUNT_INVESTORS", response.data.total);
    } catch (error) {
      console.log(error);
    }
  },

  async getInvestor(_, data) {
    try {
      const response = await elasticSearchApi.post("/investor", data);
      return response.data.data[0];
    } catch (error) {
      console.log(error);
    }
  },

  // Get invertor wallets
  async getInvestorWallets(_, user_id) {
    const response = await MicrowdAPI({ action: "getInvestorWallets", data: user_id, hide_snackbar: true });
    return response.data.data ?? [];
  },

  // Add a wallet with amount and currency
  async depositFunds(_, { investor_id, amount, currency }) {
    await MicrowdAPI({ action: "depositFunds", data: { investor_id, amount, currency } });
  },

  async getWithdraws({ commit }, data) {
    commit("SET_WITHDRAWS", []);

    try {
      const response = await elasticSearchApi.post("/withdraw", data);

      commit("SET_TOTAL_COUNT_WITHDRAWS", response.data.total);
      commit("SET_WITHDRAWS", response.data.data ?? []);
    } catch (error) {
      console.log(error);
    }
  },

  // Withdraw
  async withdraw({ commit }, data) {
    const response = await MicrowdAPI({ action: "multipleWithdraws", data });
    if (response.data.success)
      commit("SET_SNACKBAR", { show: true, text: "Reembolso realizado con éxito", color: "success" }, { root: true });
    return response;
  },

  // Delete withdraw
  async cancelWithdraw(_, withdrawId) {
    await MicrowdAPI({ action: "cancelWithdraw", data: withdrawId });
  },

  // Change withdraw status to completed
  async completeWithdraw(_, withdrawId) {
    await MicrowdAPI({ action: "completeWithdraw", data: withdrawId });
  },

  // History of a wallet
  async getWalletTransactions(_, walletId) {
    walletId = parseInt(walletId);
    const response = await MicrowdAPI({ action: "getWalletTransactions", data: walletId, hide_snackbar: true });
    return response.data.data;
  },

  // Generate and Download withdraws report of investor
  async generateWithdrawsReportByInvestor(_, investorId) {
    const { data } = await documentGeneratorApi.get(`/investor/withdraws/${investorId}`);
    return data;
  },

  // Generate and Download investments report of investor
  async generateInvestmentsReportByInvestor(_, investorId) {
    const { data } = await documentGeneratorApi.get(`/investor/investments/${investorId}`);
    return data;
  },

  // Generate and Download wallets report
  async generateWalletsReport(_, investorId) {
    const { data } = await documentGeneratorApi.get(`/investor/wallets/${investorId}`);
    return data;
  },

  async getAvailableFunds(_, { investor_id, currency }) {
    const response = await MicrowdAPI({ action: "getAvailableFunds", data: { investor_id, currency } });
    return response.data.data;
  },

  async getTotalFunds(_, data) {
    const response = await MicrowdAPI({ action: "getTotalFunds", data, hide_snackbar: true });
    return response.data.data;
  },

  async getTotalReinvestmentFundsByOffice(_, { investor_id, office_id }) {
    const response = await MicrowdAPI({ action: "getTotalReinvestmentFundsByOffice", data: { investor_id, office_id } });
    return response.data.data;
  },

  async editInvestorAutomaticInvestment(_, data) {
    await MicrowdAPI({ action: "editInvestorAutomaticInvestment", data });
  },

  async editFundInvestorExchangeRates(_, data) {
    await MicrowdAPI({ action: "editFundInvestorExchangeRates", data, hide_snackbar: true });
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_INVESTORS(state, investors) {
    state.investors = investors;
  },

  SET_INVESTOR(state, investor) {
    state.investor = investor;
  },

  SET_TOTAL_COUNT_INVESTORS(state, totalCount) {
    state.totalCountInvestors = totalCount;
  },

  SET_WITHDRAWS(state, withdraws) {
    state.withdraws = withdraws;
  },

  SET_TOTAL_COUNT_WITHDRAWS(state, totalCount) {
    state.totalCountWithdraws = totalCount;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
