import { MicrowdAPI } from "@/firebase";
import axios from "axios";
import elasticSearchApi from "@/api/elasticSearchApi";

// _____________________________________ STATE _____________________________________
const state = {
  currencies: [],
  rates: []
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  // Get list of currencies used
  async getCurrencies({ commit }, data) {
    commit("SET_CURRENCIES", []);

    try {
      const response = await elasticSearchApi.post("/currency", data);
      commit("SET_CURRENCIES", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  // Get a specific currency
  async getCurrency(_, data) {
    try {
      const response = await elasticSearchApi.post("/currency", data);
      return response.data.data[0];
    } catch (error) {
      console.log(error);
    }
  },

  // Create new currency to invest
  async createCurrency({ dispatch }, { id, name, symbol }) {
    await MicrowdAPI({ action: "createCurrency", data: { id, name, symbol } });
    await dispatch("getCurrencies");
  },

  // Editar name or symbol of a currency
  async editCurrency({ dispatch }, { id, name, symbol }) {
    await MicrowdAPI({ action: "editCurrency", data: { id, name, symbol } });
    await dispatch("getCurrencies");
  },

  // Get list of exchange rates
  async getRates({ commit }) {
    const response = await axios.get(`${process.env.VUE_APP_HOST_CURRENCYEXCHANGER}/rates`);
    commit("SET_RATES", response.data.data.rates);
  },

  // Pass a currency to investable status
  async currencyToInvestable({ dispatch }, currencyId) {
    await MicrowdAPI({ action: "currencyToInvestable", data: currencyId });
    await dispatch("getCurrencies");
  },

  // Pass a currency to uninvestable status
  async currencyToUninvestable({ dispatch }, currencyId) {
    await MicrowdAPI({ action: "currencyToUninvestable", data: currencyId });
    await dispatch("getCurrencies");
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_CURRENCIES(state, currencies) {
    state.currencies = currencies;
  },

  SET_RATES(state, rates) {
    state.rates = rates;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
