import axios from "axios";
import store from "@/store";

const documentGeneratorApi = axios.create({
  baseURL: `${process.env.VUE_APP_DOCUMENT_GENERATOR_URL}/api`,
  headers: { DOCUMENT_GENERATOR_SECRET_KEY: "secret" }
});

// Request interceptor
documentGeneratorApi.interceptors.request.use(
  function (config) {
    store.commit("SET_LOADING", true);
    const currentToken = store.state.authentication.token;
    if (currentToken) config.headers.common["Authorization"] = `Bearer ${currentToken}`;
    return config;
  },
  function (error) {
    store.commit("SET_LOADING", false);
    console.log("documentGeneratorApi request error -> ", error);
  }
);

// Response interceptor
documentGeneratorApi.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    if (response.data.code === "invalid-auth") store.dispatch("authentication/logout");
    store.commit("SET_LOADING", false);
    return response;
  },
  function (error) {
    store.commit("SET_LOADING", false);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    console.log("documentGeneratorApi response error -> ", error);
  }
);

export default documentGeneratorApi;
