import { db, auth, MicrowdAPI } from "@/firebase";

// _____________________________________ STATE _____________________________________
const state = {
  user: null,
  dbUser: null
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  // Get the current user info
  async getCurrentUserInfo({ commit }) {
    try {
      const user = auth.currentUser;
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        commit("SET_USER", {
          name: user.displayName,
          email: user.email,
          role: idTokenResult.claims.role,
          user_id: user.uid
        });
      }
    } catch (error) {
      console.log(error);
    }
  },

  // Get the info from firebasde db of user logged in
  async getDbUserInfo({ commit, getters }) {
    const { user_id } = getters["getUser"];
    const response = await db.collection("users").doc(user_id).get();
    commit("SET_DB_USER", { ...response.data(), id: user_id });
  },

  // Edit user info in firebase db of user logged in
  async editUser({ dispatch }, data) {
    await MicrowdAPI({ action: "editUser", data });
    await dispatch("getDbUserInfo");
  }
};

// _____________________________________ GETTERS _____________________________________
const getters = {
  getUser: (state) => {
    return state.user;
  },

  getDbUser: (state) => {
    return state.dbUser;
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },

  SET_DB_USER(state, dbUser) {
    dbUser.password = "";
    state.dbUser = dbUser;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
