import elasticSearchApi from "@/api/elasticSearchApi";

// _____________________________________ STATE _____________________________________
const state = {
  sectors: []
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  // Get list of sectors
  async getSectors({ commit }) {
    commit("SET_SECTORS", []);

    try {
      const response = await elasticSearchApi.post("/sector");
      commit("SET_SECTORS", response.data.data);
    } catch (error) {
      console.log(error);
    }
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_SECTORS(state, sectors) {
    state.sectors = sectors;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
