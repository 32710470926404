import { MicrowdAPI } from "@/firebase";
import elasticSearchApi from "@/api/elasticSearchApi";

// _____________________________________ STATE _____________________________________
const state = {
  countries: [],
  cities: []
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  // Get list of all countries
  async getCountries({ commit }, data) {
    commit("SET_COUNTRIES", []);

    try {
      const response = await elasticSearchApi.post("/country", data);
      const countriesSorted = response.data.data.sort(SortArray);
      commit("SET_COUNTRIES", countriesSorted);
    } catch (error) {
      console.log(error);
    }
  },

  async getCountry(_, data) {
    try {
      const response = await elasticSearchApi.post("/country", data);
      return response.data.data[0];
    } catch (error) {
      console.log(error);
    }
  },

  // Get list of all cities
  async getCities({ commit }, data) {
    commit("SET_CITIES", []);

    try {
      const response = await elasticSearchApi.post("/city", data);

      const citiesSorted = response.data.data.sort(SortArray);
      commit("SET_CITIES", citiesSorted);
    } catch (error) {
      console.log(error);
    }
  },

  async getCity(_, data) {
    try {
      const response = await elasticSearchApi.post("/city", data);
      return response.data.data[0];
    } catch (error) {
      console.log(error);
    }
  },

  async editCountry(_, data) {
    await MicrowdAPI({ action: "editCountry", data });
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_COUNTRIES(state, countries) {
    state.countries = countries;
  },

  SET_CITIES(state, cities) {
    state.cities = cities;
  }
};

function SortArray(x, y) {
  return x.name.localeCompare(y.name);
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
