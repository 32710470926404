import { MicrowdAPI } from "@/firebase";
import elasticSearchApi from "@/api/elasticSearchApi";

// _____________________________________ STATE _____________________________________
const state = {
  offices: [],
  totalCountOffices: 0,
  office: {}
};

// _____________________________________ ACTIONS _____________________________________
const actions = {
  async createOffice(_, newOffice) {
    await MicrowdAPI({ action: "createOffice", data: newOffice });
  },

  async getOffices({ commit }, data) {
    commit("SET_OFFICES", []);

    try {
      const response = await elasticSearchApi.post("/office", data);

      commit("SET_TOTAL_COUNT_OFFICES", response.data.total);
      commit("SET_OFFICES", response.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getOffice(_, data) {
    try {
      const response = await elasticSearchApi.post("/office", data);
      return response.data.data[0];
    } catch (error) {
      console.log(error);
    }
  },

  async editOffice(_, office) {
    await MicrowdAPI({ action: "editOffice", data: office });
  }
};

// _____________________________________ MUTATIONS _____________________________________
const mutations = {
  SET_OFFICES(state, offices) {
    state.offices = offices;
  },

  SET_TOTAL_COUNT_OFFICES(state, totalCount) {
    state.totalCountOffices = totalCount;
  },

  SET_OFFICE(state, office) {
    state.office = office;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
