import * as actions from "./actions";

const state = {
  token: ""
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
